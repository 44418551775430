<template>
   <AppHeader />
  <Suspense>
    <template #default>
       <app-consulting :sub_cat='sub_cat' :name_cat='name_cat'/>
    </template>
    <template #fallback>
       <Loding />
    </template>
  </Suspense>
  <AppFooter />
</template>

<script>
import { defineAsyncComponent, Suspense } from "vue";
import Loding from "@/components/Include/Loding.vue";
import AppHeader from "@/components/Include/AppHeader.vue";
import AppFooter from "@/components/Include/AppFooter.vue";
const AppConsulting = defineAsyncComponent(() =>
  import('../../components/Categorys/AppConsulting.vue')
)
export default {
  props: {
    sub_cat: String,
    name_cat: String
  },
  components: { Suspense,
    Loding,
    AppHeader,
    AppFooter,
    AppConsulting },
   name: 'Consulting'
}
</script>

<style>

</style>